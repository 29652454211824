﻿import Vue from 'vue'
import MercadoPagoBus from './mercado-pago-bus'
import MercadoPago from './mercado-pago'
import axios from 'axios';
import { VueMaskDirective } from 'v-mask'
import { runInThisContext } from 'vm'
import VueAWN from 'vue-awesome-notifications'

Vue.config.devtools = false
Vue.config.debug = false
Vue.directive('mask', VueMaskDirective)
Vue.use(VueAWN, { 
    labels: {
        alert: 'Erro'
    }
})

new Vue({
    el: '#wrapper',

    data: {
        debug: false,
        cp: null,
        //cp: '43684048828',
        
        settings: {

        },

        state: {
            step: 0,
            loading: true,
            user: null,
            sms: null,
            smsTryCount: 0,
            debt: null,
        }
    },

    components: {
        MercadoPago
    },

    created() {
        this.scrollTo(0)
        this.initializeListeners();
    },

    mounted() {
        this.loading(false)
    },

    methods: {
        log(message) {
            if (this.debug) {
                console.log(message);
            }
        },

        initializeListeners() {
            MercadoPagoBus.$on('change-step', (step) => this.changeStep(step))
            MercadoPagoBus.$on('reset', () => this.reset())

            // Eventos da Etapa 1 (verificação de SMS)
            MercadoPagoBus.$on('s1:verificar-sms', (payload) => this.verificarSMS(payload))
            MercadoPagoBus.$on('s1:proceed', () => this.changeStep(2))

            // Eventos da Etapa 2 (negociação dos valores)
            MercadoPagoBus.$on('s2:proceed', () => this.aceitarProposta())

            // Eventos da Etapa 3 (mensagem ao usuário)

            // Gerais
            MercadoPagoBus.$on('notification:error', (payload) => this.showErrorNotification(payload.message))
        },

        reset() {
            this.cp = null
            this.state.step = 0
            this.state.sms = null
            this.state.smsTryCount = 0
            this.state.user = null
            this.state.debt = null

            this.scrollTo(0)
        },

        changeStep(step) {
            this.state.step = step;
        },

        loading(loading) {
            this.state.loading = loading
        },

        scrollTo(offset) {
            $("html, body").animate({
                scrollTop: offset
            }, 500)
        },

        scrollToElement(id) {
            this.$nextTick(() => {
                this.scrollTo($(`#${id}`).offset().top - 80)
            })
        },

        showErrorNotification(message) {
            this.$awn.alert(message)
        },

        showInfoNotification(message) {
            this.$awn.info(message)
        },

        async verificarCP() {
            // Loader
            this.loading(true)

            try {
                // TODO - Requisição para API - Consultar CPF/CNPJ e enviar SMS para o celular vinculado
                let result = await axios.get('api/', { params: {
                    step: 1,
                    cpf: this.cp
                }})

                if (typeof(result.data.D) !== 'undefined') {
                    this.state.user = {
                        cp: this.cp,
                        phone: result.data.D.cel,
                        caso: result.data.D.caso
                    }

                    // DEBUG
                    //this.$awn.info(`MENSAGEM PARA TESTES -> O CÓDIGO SMS É ${result.data.D.auxDebugVaiTirarDepois}`);
                    //this.log(`SMS: ${result.data.D.auxDebugVaiTirarDepois}`)

                    // Vai para a etapa 1 (confirmação do SMS)
                    this.changeStep(1)

                    // Anima até o elemento
                    this.scrollToElement('negociacao')

                }
                else if (typeof(result.data.S) !== 'undefined') {
                    // Verifica se foi NOT FOUND, caso seja, o usuário não tem nenhum débito
                    if (result.data.S.info == 'not found') {
                        this.$awn.info('O CPF informado não foi encontrado em nossa base de dados.')
                    }
                }
            } catch (err) {
                this.showErrorNotification('Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.')
            }

            // Loader
            setTimeout(() => { this.loading(false) }, 5000)
        },

        async verificarSMS(codigo) {
            // Loader
            this.loading(true)

            try {
                // TODO - Requisição para API - Confirmar o código digitado e receber as informações de débitos
                // API Mock para testes
                let result = await axios.get('api/', { params: {
                    step: 2,
                    cpf: this.cp,
                    sms: codigo,
                    caso: this.state.user.caso
                }})

                // Aumenta as tentativas de SMS para futuramente poder barrar depois de X tentativas
                this.state.smsTryCount++

                if (typeof(result.data.D) != 'undefined') {
                    this.state.sms = codigo
                    this.state.debt = {
                        amount: parseFloat(result.data.D.sim[0].DividaTotal.replace(',', '.')),
                        discount: parseFloat(result.data.D.sim[0].ValorDesconto.replace(',', '.')),
                        //total: parseFloat(result.data.D.proposta.replace(',', '.')),
                        paymentDate: '01/01/0001'
                        //paymentDate: result.data.D.dpg.replaceAll('-', '/')
                    }
                    this.state.debt.total = this.state.debt.amount - this.state.debt.discount
                    if (this.state.debt.total <= 0) {
                        this.showInfoNotification("Por gentileza, para consultar os dados de seu contrato, entre em contato conosco através do WhatsApp.")
                        this.reset()
                    } else {
                        this.state.debt.contracts = []
                        Object.keys(result.data.D.cnt).forEach((key) => {
                            this.state.debt.contracts.push(result.data.D.cnt[key])
                        })
                        
                        MercadoPagoBus.$emit('s1:verificar-sms:success')
                    }
                } else {
                    MercadoPagoBus.$emit('s1:verificar-sms:error')
                }
            } catch (err) {
                this.showErrorNotification('Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.')
            }

            // Loader
            this.loading(false)
        },

        async aceitarProposta() {
            // Loader
            this.loading(true)

            try {
                // TODO - Requisição para API - Confirmar a proposta apresentada e disparar a geração do boleto para o cliente
                // API Mock para testes
                let result = await axios.get('api/', { params: {
                    step: 3,
                    cpf: this.state.user.cp,
                    sms: this.state.sms,
                    caso: this.state.user.caso,
                    valor: this.state.debt.amount
                }})

                // Adiciona o e-mail ao usuário
                if (typeof(result.data.D) !== 'undefined') {
                    this.state.user.email = result.data.D.email
                }

                // Vai para a etapa 3 (mensagem final ao usuário)
                this.changeStep(3)

                // Anima até o elemento
                this.scrollToElement('negociacao')
                
            } catch (err) {
                this.showErrorNotification('Ocorreu um erro ao processar sua solicitação. Por favor, tente novamente mais tarde.')
            }

            // Loader
            this.loading(false)
        },
    }
});