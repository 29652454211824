import { render, staticRenderFns } from "./mercado-pago-step-2.vue?vue&type=template&id=055fd306&"
import script from "./mercado-pago-step-2.vue?vue&type=script&lang=js&"
export * from "./mercado-pago-step-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\xampp\\htdocs\\fattor\\vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('055fd306')) {
      api.createRecord('055fd306', component.options)
    } else {
      api.reload('055fd306', component.options)
    }
    module.hot.accept("./mercado-pago-step-2.vue?vue&type=template&id=055fd306&", function () {
      api.rerender('055fd306', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "mercado-pago/mercado-pago-step-2.vue"
export default component.exports