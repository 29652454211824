<template>
    <div id="negociacao">
        <ul class="process-steps process-3 row col-mb-30 justify-content-center mb-4" v-if="state.step != 0">
            <li class="col-sm-6 col-lg-3">
                <span class="i-circled i-alt mx-auto" :class="{ 'i-bordered': state.step != 1 }">1</span>
                <h5>Confirmação dos Dados</h5>
            </li>
            <li class="col-sm-6 col-lg-3">
                <span class="i-circled i-alt mx-auto" :class="{ 'i-bordered': state.step != 2 }">2</span>
                <h5>Geração da Proposta</h5>
            </li>
            <li class="col-sm-6 col-lg-3">
                <span class="i-circled i-alt mx-auto" :class="{ 'i-bordered': state.step != 3 }">3</span>
                <h5>Negociação Completa!</h5>
            </li>
        </ul>
        <div>
            <step-1 v-if="state.step == 1" :state="state"></step-1>
            <step-2 v-else-if="state.step == 2" :state="state"></step-2>
            <step-3 v-else-if="state.step == 3" :state="state"></step-3>
        </div>
    </div>
</template>

<script>
import MercadoPagoBus from './mercado-pago-bus';
import Step1 from './mercado-pago-step-1'
import Step2 from './mercado-pago-step-2'
import Step3 from './mercado-pago-step-3'

export default {
    name: 'mercado-pago',
    
    components: {
        Step1,
        Step2,
        Step3
    },

    props: {
        settings: null,
        state: null
    },

    data: () => ({
        
    }),

    methods: {

    }
}
</script>

<style scoped>
.i-circled {
    cursor: default !important;
}
</style>