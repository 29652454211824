var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row pricing col-mb-30 mb-4 text-center" }, [
      _c("div", { staticClass: "col-md-6 col-lg-4" }, [
        _c(
          "div",
          {
            staticClass:
              "pricing-box pricing-simple p-5 bg-light border-top border-success text-center animated pulse infinite"
          },
          [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pricing-price" },
              [
                _c("span", { staticClass: "price-unit" }, [_vm._v("R$")]),
                _c("animated-number", {
                  attrs: {
                    value: _vm.state.debt.total,
                    formatValue: _vm.formatarValor,
                    duration: 1000
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "text-center" }, [
              _c(
                "button",
                {
                  staticClass: "button button-xlarge text-end",
                  on: {
                    click: function($event) {
                      return _vm.aceitarProposta()
                    }
                  }
                },
                [
                  _vm._v("Ativar Desconto"),
                  _c("i", { staticClass: "icon-circle-arrow-right" })
                ]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-lg-4 order-md-first" }, [
        _c(
          "div",
          {
            staticClass:
              "pricing-box pricing-simple p-5 bg-light border-top border-danger text-center"
          },
          [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pricing-price" },
              [
                _c("span", { staticClass: "price-unit" }, [_vm._v("R$")]),
                _c("animated-number", {
                  attrs: {
                    value: _vm.state.debt.amount,
                    formatValue: _vm.formatarValor,
                    duration: 500
                  }
                })
              ],
              1
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6 col-lg-4" }, [
        _c(
          "div",
          {
            staticClass:
              "pricing-box pricing-simple p-5 bg-light border-top border-primary text-center"
          },
          [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pricing-price" },
              [
                _c("span", { staticClass: "price-unit" }, [_vm._v("R$")]),
                _c("animated-number", {
                  attrs: {
                    value: _vm.state.debt.discount,
                    formatValue: _vm.formatarValor,
                    duration: 500
                  }
                })
              ],
              1
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "promo promo-border promo-full p-4 p-md-5 mt-5 mb-1 text-left"
      },
      [
        _c("div", { staticClass: "container clearfix" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-12 col-lg" }, [
              _c("h3", [_vm._v("Contratos da sua negociação")]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _vm._v(
                    "\n                        Os valores apresentados são referentes ao(s) contrato(s) "
                  ),
                  _vm._l(_vm.state.debt.contracts, function(contract, index) {
                    return _c(
                      "strong",
                      { key: index },
                      [
                        index > 0 ? [_vm._v(", ")] : _vm._e(),
                        _vm._v(_vm._s(contract.NumContrato))
                      ],
                      2
                    )
                  }),
                  _vm._v(".\n                    ")
                ],
                2
              )
            ])
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "promo promo-border promo-full p-4 p-md-5 mt-1 mb-5 text-left",
        staticStyle: { "border-top": "0" }
      },
      [
        _c("div", { staticClass: "container clearfix" }, [
          _c("div", { staticClass: "row align-items-center" }, [
            _c("div", { staticClass: "col-12 col-lg" }, [
              _c("h3", [
                _vm._v("Não reconhece os valores ou ficou com alguma dúvida?")
              ]),
              _vm._v(" "),
              _c(
                "span",
                [
                  _vm._v(
                    "\n                        Caso não reconheça os valores informados ou está com dúvidas em relação ao processo, entre em contato conosco através do nosso "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href:
                          "https://api.whatsapp.com/send?phone=551135143300",
                        target: "_blank"
                      }
                    },
                    [_vm._v("WhatsApp!")]
                  ),
                  _vm._v(" "),
                  _vm.exibirAjudaMercadoPago
                    ? [
                        _vm._v(
                          "Se preferir, você também pode entrar em contato com o suporte do "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.mercadopago.com.br/ajuda",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Mercado Pago")]
                        ),
                        _vm._v(".")
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _c("br")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-lg-auto mt-4 mt-lg-0" }, [
              _c(
                "a",
                {
                  staticClass:
                    "button button-large button-green button-circle m-0",
                  attrs: {
                    href: "https://api.whatsapp.com/send?phone=551135143300",
                    target: "_blank"
                  }
                },
                [_vm._v("(11) 3514-3300")]
              ),
              _vm._v(" "),
              _vm.exibirAjudaMercadoPago
                ? _c(
                    "a",
                    {
                      staticClass:
                        "button button-large button-blue button-circle m-0",
                      attrs: {
                        href: "https://www.mercadopago.com.br/ajuda",
                        target: "_blank"
                      }
                    },
                    [_vm._v("Mercado Pago")]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fancy-title title-border title-center" }, [
      _c("h3", [_vm._v("Geração da Proposta")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricing-title" }, [
      _c("h3", [_vm._v("Valor Final")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricing-title" }, [
      _c("h3", [_vm._v("Valor do Débito")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pricing-title" }, [
      _c("h3", [_vm._v("Nosso Desconto")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }