var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center", attrs: { id: "etapa3" } }, [
    _vm._m(0),
    _vm._v(" "),
    _c("h3", [
      _vm._v(
        "\n        Obrigado por negociar com a Fattor! O boleto da negociação está sendo processado e será enviado para o e-mail "
      ),
      _c("strong", [_vm._v(_vm._s(_vm.state.user.email))]),
      _vm._v(" em alguns instantes.\n    ")
    ]),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "button button-xlarge text-end",
          on: {
            click: function($event) {
              return _vm.reset()
            }
          }
        },
        [
          _vm._v("Nova Negociação"),
          _c("i", { staticClass: "icon-circle-arrow-right" })
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fancy-title title-border title-center" }, [
      _c("h3", [_vm._v("Negociação Completa!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", [
      _vm._v(
        "\n        Caso ainda tenha alguma dúvida, entre em contato conosco através do telefone ou WhatsApp "
      ),
      _c(
        "a",
        { attrs: { href: "https://api.whatsapp.com/send?phone=551135143300" } },
        [_vm._v("(11) 3514-3300")]
      ),
      _vm._v(".\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }