<template>
    <div>
        <div class="fancy-title title-border title-center">
            <h3>Geração da Proposta</h3>
        </div>

        <div class="row pricing col-mb-30 mb-4 text-center">
            <div class="col-md-6 col-lg-4">

                <div class="pricing-box pricing-simple p-5 bg-light border-top border-success text-center animated pulse infinite">
                    <div class="pricing-title">
                        <h3>Valor Final</h3>
                    </div>
                    <div class="pricing-price">
                        <span class="price-unit">R$</span><animated-number :value="state.debt.total" :formatValue="formatarValor" :duration="1000"></animated-number>
                    </div>
                    <div class="text-center">
                        <button class="button button-xlarge text-end" @click="aceitarProposta()">Ativar Desconto<i class="icon-circle-arrow-right"></i></button>
                    </div>
                </div>

            </div>

            <div class="col-md-6 col-lg-4 order-md-first">

                <div class="pricing-box pricing-simple p-5 bg-light border-top border-danger text-center">
                    <div class="pricing-title">
                        <h3>Valor do Débito</h3>
                    </div>
                    <div class="pricing-price">
                        <span class="price-unit">R$</span><animated-number :value="state.debt.amount" :formatValue="formatarValor" :duration="500"></animated-number>
                    </div>
                </div>

            </div>

            <div class="col-md-6 col-lg-4">

                <div class="pricing-box pricing-simple p-5 bg-light border-top border-primary text-center">
                    <div class="pricing-title">
                        <h3>Nosso Desconto</h3>
                    </div>
                    <div class="pricing-price">
                        <span class="price-unit">R$</span><animated-number :value="state.debt.discount" :formatValue="formatarValor" :duration="500"></animated-number>
                    </div>
                </div>

            </div>

            
        </div>

        <div class="promo promo-border promo-full p-4 p-md-5 mt-5 mb-1 text-left">
            <div class="container clearfix">
                <div class="row align-items-center">
                    <div class="col-12 col-lg">
                        <h3>Contratos da sua negociação</h3>
                        <span>
                            Os valores apresentados são referentes ao(s) contrato(s) <strong v-for="(contract, index) in state.debt.contracts" :key="index"><template v-if="index >0">, </template>{{ contract.NumContrato }}</strong>.
                        </span>
                    </div>
                </div>
            </div>
        </div>      

        <div class="promo promo-border promo-full p-4 p-md-5 mt-1 mb-5 text-left" style="border-top: 0;">
            <div class="container clearfix">
                <div class="row align-items-center">
                    <div class="col-12 col-lg">
                        <h3>Não reconhece os valores ou ficou com alguma dúvida?</h3>
                        <span>
                            Caso não reconheça os valores informados ou está com dúvidas em relação ao processo, entre em contato conosco através do nosso <a href="https://api.whatsapp.com/send?phone=551135143300" target="_blank">WhatsApp!</a>
                            <template v-if="exibirAjudaMercadoPago">Se preferir, você também pode entrar em contato com o suporte do <a href="https://www.mercadopago.com.br/ajuda" target="_blank">Mercado Pago</a>.</template>
                        </span>
                        <br/>
                    </div>
                    <div class="col-12 col-lg-auto mt-4 mt-lg-0">
                        <a href="https://api.whatsapp.com/send?phone=551135143300" class="button button-large button-green button-circle m-0" target="_blank">(11) 3514-3300</a>
                        <a href="https://www.mercadopago.com.br/ajuda" class="button button-large button-blue button-circle m-0" target="_blank" v-if="exibirAjudaMercadoPago">Mercado Pago</a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import AnimatedNumber from 'animated-number-vue'
import MercadoPagoBus from './mercado-pago-bus'

export default {
    name: 'mercado-pago-step-2',

    components: {
        AnimatedNumber
    },

    props: {
        state: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        exibirAjudaMercadoPago: false
    }),

    methods: {
        aceitarProposta() {
            MercadoPagoBus.$emit('s2:proceed');
        },

        formatarValor(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    }
}
</script>