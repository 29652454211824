<template>
    <div id="etapa3" class="text-center">
        <div class="fancy-title title-border title-center">
            <h3>Negociação Completa!</h3>
        </div>
        <h3>
            Obrigado por negociar com a Fattor! O boleto da negociação está sendo processado e será enviado para o e-mail <strong>{{ state.user.email }}</strong> em alguns instantes.
        </h3>
        <h4>
            Caso ainda tenha alguma dúvida, entre em contato conosco através do telefone ou WhatsApp <a href="https://api.whatsapp.com/send?phone=551135143300">(11) 3514-3300</a>.
        </h4>

        <div class="text-center">
            <button class="button button-xlarge text-end" @click="reset()">Nova Negociação<i class="icon-circle-arrow-right"></i></button>
        </div>
    </div>
</template>

<script>
import MercadoPagoBus from './mercado-pago-bus'

export default {
    name: 'mercado-pago-step-3',

    props: {
        state: {
            type: Object,
            required: true
        }
    },

    data: () => ({

    }),

    methods: {
        reset() {
            MercadoPagoBus.$emit('reset')
        }
    }
}
</script>