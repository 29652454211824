var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "negociacao" } }, [
    _vm.state.step != 0
      ? _c(
          "ul",
          {
            staticClass:
              "process-steps process-3 row col-mb-30 justify-content-center mb-4"
          },
          [
            _c("li", { staticClass: "col-sm-6 col-lg-3" }, [
              _c(
                "span",
                {
                  staticClass: "i-circled i-alt mx-auto",
                  class: { "i-bordered": _vm.state.step != 1 }
                },
                [_vm._v("1")]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Confirmação dos Dados")])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "col-sm-6 col-lg-3" }, [
              _c(
                "span",
                {
                  staticClass: "i-circled i-alt mx-auto",
                  class: { "i-bordered": _vm.state.step != 2 }
                },
                [_vm._v("2")]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Geração da Proposta")])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "col-sm-6 col-lg-3" }, [
              _c(
                "span",
                {
                  staticClass: "i-circled i-alt mx-auto",
                  class: { "i-bordered": _vm.state.step != 3 }
                },
                [_vm._v("3")]
              ),
              _vm._v(" "),
              _c("h5", [_vm._v("Negociação Completa!")])
            ])
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _vm.state.step == 1
          ? _c("step-1", { attrs: { state: _vm.state } })
          : _vm.state.step == 2
          ? _c("step-2", { attrs: { state: _vm.state } })
          : _vm.state.step == 3
          ? _c("step-3", { attrs: { state: _vm.state } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }