var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    false && _vm.error
      ? _c("div", { staticClass: "style-msg errormsg" }, [_vm._m(1)])
      : _vm._e(),
    _vm._v(" "),
    _c("h3", { staticClass: "text-center" }, [
      _vm._v(
        "\n        Em alguns instantes, um SMS com um código de 6 dígitos chegará no telefone terminado em "
      ),
      _c("strong", [_vm._v(_vm._s(_vm.state.user.phone.substr(-4)))]),
      _vm._v(". "),
      _c("br"),
      _vm._v(
        "\n        Dependendo da operadora, esse código pode demorar até 5 minutos para ser enviado. Não recarregue a página, aguarde o recebimento e informe o código recebido para avançar para a próxima etapa.\n    "
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container__item text-center" }, [
      _c("form", { staticClass: "form" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.codigo,
              expression: "codigo"
            }
          ],
          staticClass: "form__field",
          attrs: { type: "number", placeholder: "Código. Ex.: 123456" },
          domProps: { value: _vm.codigo },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.codigo = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "btn-codigo btn-codigo--primary btn-codigo--inside uppercase",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.verificarSMS()
              }
            }
          },
          [_vm._v("Confirmar")]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fancy-title title-border title-center" }, [
      _c("h3", [_vm._v("Confirmação dos Dados")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sb-msg" }, [
      _c("i", { staticClass: "icon-remove" }),
      _c("strong", [_vm._v("Erro!")]),
      _vm._v(" O código informado é inválido.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }