<template>
    <div>
        <div class="fancy-title title-border title-center">
            <h3>Confirmação dos Dados</h3>
        </div>
        <div class="style-msg errormsg" v-if="false && error">
            <div class="sb-msg"><i class="icon-remove"></i><strong>Erro!</strong> O código informado é inválido.</div>
        </div>
        <h3 class="text-center">
            Em alguns instantes, um SMS com um código de 6 dígitos chegará no telefone terminado em <strong>{{ state.user.phone.substr(-4) }}</strong>. <br/>
            Dependendo da operadora, esse código pode demorar até 5 minutos para ser enviado. Não recarregue a página, aguarde o recebimento e informe o código recebido para avançar para a próxima etapa.
        </h3>
        
        <div class="container__item text-center">
            <form class="form">
                <input type="number" class="form__field" v-model="codigo" placeholder="Código. Ex.: 123456" />
                <button type="button" class="btn-codigo btn-codigo--primary btn-codigo--inside uppercase" @click="verificarSMS()">Confirmar</button>
            </form>
        </div>
    </div>
</template>

<script>
import MercadoPagoBus from './mercado-pago-bus'
export default {
    name: 'mercado-pago-step-1',

    data: () => ({
        codigo: null,

        error: false
    }),

    props: {
        state: {
            type: Object,
            required: true
        }
    },

    mounted() {
        this.initializeListeners();
    },

    methods: {
        initializeListeners() {
            MercadoPagoBus.$on('s1:verificar-sms:error', () => this.verificarSMSHandleResult({ success: false }))
            MercadoPagoBus.$on('s1:verificar-sms:success', () => this.verificarSMSHandleResult({ success: true }))
        },

        verificarSMS() {
            MercadoPagoBus.$emit("s1:verificar-sms", this.codigo)
        },

        verificarSMSHandleResult(result) {
            this.codigo = null

            if (!result.success) {
                this.error = !result.success
                MercadoPagoBus.$emit('notification:error', { message: 'O código informado é inválido.'})
            } else {
                MercadoPagoBus.$emit('s1:proceed')
            }
        }
    }
}
</script>